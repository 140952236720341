
export default class InputsJuniorAndSeniorModel {
  constructor({
    masculineBsquatLiterature,
    feminineBsquatLiterature,
    masculineBrenchpressLiterature,
    feminineBrenchpressLiterature,
    masculineDeadliftLiterature,
    feminineDeadliftLiterature,
    masculineCleanLiterature,
    feminineCleanLiterature,
    jumpLiterature,
  } = {}) {
    this.masculineBsquatLiterature = masculineBsquatLiterature
    this.feminineBsquatLiterature = feminineBsquatLiterature
    this.masculineBrenchpressLiterature = masculineBrenchpressLiterature
    this.feminineBrenchpressLiterature = feminineBrenchpressLiterature
    this.masculineDeadliftLiterature = masculineDeadliftLiterature
    this.feminineDeadliftLiterature = feminineDeadliftLiterature
    this.masculineCleanLiterature = masculineCleanLiterature
    this.feminineCleanLiterature = feminineCleanLiterature
    this.jumpLiterature = jumpLiterature
  }
}