import GuidUtils from '@/shared/utils/guid'

export default class StudentRegistered {
  constructor({
    id,
    birthDate,
    classId,
    dateEnd,
    dateStart,
    studentAge,
    studentBirthDate,
    studentCode,
    studentGender,
    studentId,
    studentName
    
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.birthDate = birthDate,
    this.classId = classId,
    this.dateEnd = dateEnd,
    this.dateStart = dateStart,
    this.studentAge = studentAge,
    this.studentBirthDate = studentBirthDate,
    this.studentCode = studentCode,
    this.studentGender = studentGender,
    this.studentId = studentId,
    this.studentName = studentName
  }
}