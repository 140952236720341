export default class WaitingListCriteria {
  constructor({
    localTrainingId,
    localTrainingName,
    modalityId,
    modalityName,
    categoryId,
    categoryName,
    teacherId,
    name,

  } = {}) {
    this.localTrainingId = localTrainingId,
      this.localTrainingName = localTrainingName,
      this.modalityId = modalityId,
      this.modalityName = modalityName,
      this.categoryId = categoryId,
      this.categoryName = categoryName,
      this.teacherId = teacherId,
      this.name = name
  }
}