import GuidUtils from '@/shared/utils/guid'

export default class GymRatingModel {
  constructor({
    id,
    name,
    category,
    classId,
    season,
    juvenileBsquatLiterature,
    juvenileBrenchpressLiterature,
    juvenileDeadliftLiterature,
    juvenileCleanLiterature,
    masculineBsquatLiterature,
    feminineBsquatLiterature,
    masculineBrenchpressLiterature,
    feminineBrenchpressLiterature,
    masculineDeadliftLiterature,
    feminineDeadliftLiterature,
    masculineCleanLiterature,
    feminineCleanLiterature,
    jumpLiterature,
    studentsIds
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.name = name
    this.category = category
    this.classId = classId
    this.season = season
    this.juvenileBsquatLiterature = juvenileBsquatLiterature
    this.juvenileBrenchpressLiterature = juvenileBrenchpressLiterature
    this.juvenileDeadliftLiterature = juvenileDeadliftLiterature
    this.juvenileCleanLiterature = juvenileCleanLiterature
    this.jumpLiterature = jumpLiterature
    this.masculineBsquatLiterature = masculineBsquatLiterature
    this.feminineBsquatLiterature = feminineBsquatLiterature
    this.masculineBrenchpressLiterature = masculineBrenchpressLiterature
    this.feminineBrenchpressLiterature = feminineBrenchpressLiterature
    this.masculineDeadliftLiterature = masculineDeadliftLiterature
    this.feminineDeadliftLiterature = feminineDeadliftLiterature
    this.masculineCleanLiterature = masculineCleanLiterature
    this.feminineCleanLiterature = feminineCleanLiterature
    this.studentsIds = studentsIds
  }
}