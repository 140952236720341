export default class InputsJuvenileIModel {
  constructor({
    juvenileBsquatLiterature,
    juvenileBrenchpressLiterature,
    juvenileDeadliftLiterature,
    juvenileCleanLiterature
  } = {}) {
    this.juvenileBsquatLiterature = juvenileBsquatLiterature
    this.juvenileBrenchpressLiterature = juvenileBrenchpressLiterature
    this.juvenileDeadliftLiterature = juvenileDeadliftLiterature
    this.juvenileCleanLiterature = juvenileCleanLiterature
  }
}