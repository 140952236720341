import InputsJuniorAndSeniorModel from "@/views/gym-rating/components/inputsJuniorAndSenior/inputsJuniorAndSeniorModel"

export default {
  name: 'InputsJuniorAndSenior',

  props: {
    item: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    inputsJuniorAndSeniorModel: new InputsJuniorAndSeniorModel()
  }),

  watch: {
    item: {
      handler: function (value) {
        if (value) {
          this.inputsJuniorAndSeniorModel = new InputsJuniorAndSeniorModel(value)
        }
      },
      immediate: true
    },
  },
  
  methods: {
    setZero() {
      if (this.inputsJuniorAndSeniorModel.jumpLiterature == '') {
        this.inputsJuniorAndSeniorModel.jumpLiterature = 0
      }
    },

    checkValue() {
      this.$emit('valueInputsJuniorAndSenior', this.inputsJuniorAndSeniorModel)
    }
  }
}