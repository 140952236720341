import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'gymrating'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
})
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const SaveWeightOnTables = (id, studentId, weight, height) => SocialProjectManager.post(`${resourceName}/save-weight-table/${id}/student/${studentId}/weight/${weight}/height/${height}`)

const GymRatingRepository = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  SaveWeightOnTables
}

export default GymRatingRepository