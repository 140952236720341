import InputsJuvenileIModel from "@/views/gym-rating/components/inputsJuvenile/inputsJuvenileIModel"

export default {
  name: 'InputsJuvenile',

  props: {
    item: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    inputsJuvenileIModel: new InputsJuvenileIModel()
  }),

  watch: {
    item: {
      handler: function (value) {
        if (value) {
          this.inputsJuvenileIModel = new InputsJuvenileIModel(value)
        }
      },
      immediate: true
    },
  },

  methods: {
    checkValue() {
      this.$emit('valueInputsJuvenile', this.inputsJuvenileIModel)
    }
  }
}