import TableRegisteredStudentsNoSelectable from "@/components/layout/TableRegisteredStudentsNoSelectable/tableRegisteredStudentsNoSelectable.vue"
import InputsJuniorAndSenior from "@/views/gym-rating/components/inputsJuniorAndSenior/inputsJuniorAndSenior.vue"
import InputsJuvenile from "@/views/gym-rating/components/inputsJuvenile/inputsJuvenile.vue"
import GymRatingRepository from '@/shared/http/repositories/socialProject/gymRating'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import classCriteria from '@/shared/models/criteria/classcriteria'
import GymRatingModel from '@/shared/models/gymRating'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import GuidUtils from '@/shared/utils/guid'

export default {
  components: {
    [TableRegisteredStudentsNoSelectable.name]: TableRegisteredStudentsNoSelectable,
    [InputsJuniorAndSenior.name]: InputsJuniorAndSenior,
    [InputsJuvenile.name]: InputsJuvenile,
    [BreadCrumb.name]: BreadCrumb
  },

  data: () => ({
    gymRatingModel: new GymRatingModel(),
    classCriteria: new classCriteria(),
    showInputsJuniorAndSenior: false,
    showModalSelectCategory: false,
    showInputsJuvenile: false,
    getStudentsId: true,
    isNew: null,
    loadInputsJuniorAndSenior: {},
    loadInputsJuvenile: {},
    optionsTeachers: [],
    optionsClass: [],
    studentsId: [],
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Academia',
            href: this.$router.resolve({ name: 'gymRating' }).href
          },
          {
            text: 'Temporada',
            active: true
          }
        ]
      }
    },

    checkClassId() {
      const hasClassId = this.gymRatingModel.classId

      if (hasClassId){
        return this.gymRatingModel.classId
      }
    },

    disableInputs() {
      if (!this.isNew) {
        return true
      }
      return false
    }
  },

  created() {
    this.getAllTeacher()

    this.$validator.reset()

    this.isNew = !this.$route.params.id || this.$route.params.id == GuidUtils.isEmpty
    if (this.isNew) {
      this.showModalSelectCategory = true
      return
    }
    loading.push()
    this.getClass()
    this.getById()
    loading.pop()
  },

  methods: {
    onSave() {
      this.gymRatingModel.studentsIds = this.studentsId

      if (this.gymRatingModel.studentsIds.length == 0) {
        toast.info('Não é possível continuar. Turma sem aluno cadastrado!', 'AVISO')
        this.gymRatingModel.classId = null
        this.gymRatingModel.studentsIds = []
        return
      }

      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.info('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        this.gymRatingModel.name = this.gymRatingModel.name.toUpperCase()
        
        if (this.validateInputs()) {
          if (this.isNew) {
            this.onSaveCreate()
            return
          }
  
          this.onSaveEdit()
        }
      })
    },

    validateInputs() {
      if (this.gymRatingModel.category === 'JUVENIL I' || this.gymRatingModel.category === 'JUVENIL II') {
        if (this.validateJuvenile() == false) {
          toast.info('Preencha todos os campos', 'AVISO')
          return false
        }
        return true
      }
      if (this.validateJuniorAndSenior() == false) {
        toast.info('Preencha todos os campos', 'AVISO')
        return false
      }
      return true
    },

    onSaveCreate() {
      loading.push()
      GymRatingRepository.Create(this.gymRatingModel)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()

          toast.success('Registro salvo com sucesso', 'SUCESSO')
          this.backTo()
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    onSaveEdit() {
      loading.push()
      GymRatingRepository.Update(this.gymRatingModel)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()

          toast.success('Registro alterado com sucesso', 'SUCESSO')
          this.backTo()
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível editar registro.', 'ERRO')
          loading.pop()
        })
    },

    onLoadInput() {
      const category = this.gymRatingModel.category
      if (category === 'JUVENIL I' || category === 'JUVENIL II' ) {
        this.showInputsJuvenile = true
        this.loadInputsJuvenile = this.gymRatingModel
        return
      }
      this.showInputsJuniorAndSenior = true
      this.loadInputsJuniorAndSenior = this.gymRatingModel
    },

    getById() {
      loading.push()
      GymRatingRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.gymRatingModel = new GymRatingModel(res.data.data)
          this.onLoadInput()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar esta turma', 'ERRO')
        })
    },
 
    goToCategoryJuvenileI() {
      this.gymRatingModel.category = 'JUVENIL I'
      this.showInputsJuvenile = true
      this.showModalSelectCategory = false
    },
    
    goToCategoryJuvenileII() {
      this.gymRatingModel.category = 'JUVENIL II'
      this.showInputsJuvenile = true
      this.showModalSelectCategory = false
    },
    
    goToCategoryJuniorAndSenior() {
      this.gymRatingModel.category = 'JUNIOR E SENIOR'
      this.showInputsJuniorAndSenior = true
      this.showModalSelectCategory = false
    },

    getClass() {
      if (!this.classCriteria.teacherId && this.isNew) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.classCriteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          this.$refs.registeredStudentNoSelectable.showAction = false
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    onStudentsId (ids) {
      this.studentsId = ids
    },

    valueInputsJuniorAndSenior(value) {
      this.gymRatingModel.masculineBrenchpressLiterature = value.masculineBrenchpressLiterature
      this.gymRatingModel.feminineBrenchpressLiterature = value.feminineBrenchpressLiterature
      this.gymRatingModel.masculineDeadliftLiterature = value.masculineDeadliftLiterature
      this.gymRatingModel.feminineDeadliftLiterature = value.feminineDeadliftLiterature
      this.gymRatingModel.masculineBsquatLiterature = value.masculineBsquatLiterature
      this.gymRatingModel.masculineCleanLiterature = value.masculineCleanLiterature
      this.gymRatingModel.feminineBsquatLiterature = value.feminineBsquatLiterature
      this.gymRatingModel.feminineCleanLiterature = value.feminineCleanLiterature
      this.gymRatingModel.jumpLiterature = value.jumpLiterature
    },

    valueInputsJuvenile(value) {
      this.gymRatingModel.juvenileBrenchpressLiterature = value.juvenileBrenchpressLiterature
      this.gymRatingModel.juvenileDeadliftLiterature = value.juvenileDeadliftLiterature
      this.gymRatingModel.juvenileBsquatLiterature = value.juvenileBsquatLiterature
      this.gymRatingModel.juvenileCleanLiterature = value.juvenileCleanLiterature
    },

    validateJuvenile() {
      const juvBrenchPress = this.gymRatingModel.juvenileBrenchpressLiterature
      const juvDeadLift = this.gymRatingModel.juvenileDeadliftLiterature
      const juvBSquat = this.gymRatingModel.juvenileBsquatLiterature
      const juvClean = this.gymRatingModel.juvenileCleanLiterature

      if (!juvBrenchPress || !juvDeadLift || !juvBSquat || !juvClean) {
        return false
      }
      return true
    },

    validateJuniorAndSenior() {
      const mascBrenchPress = this.gymRatingModel.masculineBrenchpressLiterature
      const femBrenchPress = this.gymRatingModel.feminineBrenchpressLiterature
      const mascDeadLift = this.gymRatingModel.masculineDeadliftLiterature
      const femDeadLift = this.gymRatingModel.feminineDeadliftLiterature
      const mascBsquat = this.gymRatingModel.masculineBsquatLiterature
      const mascClean = this.gymRatingModel.masculineCleanLiterature
      const femBsquat = this.gymRatingModel.feminineBsquatLiterature
      const femClean = this.gymRatingModel.feminineCleanLiterature

      if (!mascBrenchPress || !femBrenchPress || !mascDeadLift || !femDeadLift || !mascBsquat || !mascClean ||
        !femBsquat || !femClean) {
          return false
        }
        return true
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    backTo() { 
      this.$router.go(-1)
    }
  }
}